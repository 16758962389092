import { ConnectedPosition, FlexibleConnectedPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AwesomeTooltipComponent } from './awesome-tooltip.component';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';

@Directive({
  selector: '[appAwesomeTooltip]',
})
export class AwesomeTooltipDirective implements OnInit, OnDestroy {
  @Input() lgSize: boolean = false;
  @Input() editDate: boolean = false;
  @Input() isNotification: boolean = false;
  @Input() isViventium: boolean = false;
  positionsObj: ConnectedPosition;

  private overlayRef: OverlayRef;
  private touched: boolean = false;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay,
    private router: Router,
  ) {}

  ngOnInit(): void {
    window.addEventListener('scroll', this.hide.bind(this), true);
    document.addEventListener('touchstart', this.onDocumentTouch.bind(this), true);
    this.setPositions();

    const positionStrategy: FlexibleConnectedPositionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([this.positionsObj]);

    this.overlayRef = this.overlay.create({ positionStrategy });
    this.router.events
      .pipe(
        filter((event): boolean => event instanceof NavigationEnd),
        tap(() => this.hide()),
        take(1),
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.touched) this.overlayRef?.detach();
  }

  private setPositions(): void {
    if (this.isNotification) {
      this.positionsObj = {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
      };
    } else if (this.isViventium) {
      this.positionsObj = {
        originX: 'start',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
      };
    } else {
      this.positionsObj = {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
      };
    }
  }

  @HostListener('touchstart', ['$event'])
  onMobileTouch(): void {
    this.touched = true;
    this.toggleTooltip();
  }

  @HostListener('mouseenter')
  show(): void {
    if (!this.touched) {
      this.attachTooltip();
    }
  }

  @HostListener('mouseleave')
  hide(): void {
    if (!this.touched) {
      this.overlayRef?.detach();
    }
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.hide();
  }

  private toggleTooltip(): void {
    if (this.overlayRef?.hasAttached()) {
      this.hide();
    } else {
      this.attachTooltip();
    }
  }

  private attachTooltip(): void {
    const component: any = this.elementRef.nativeElement.classList.contains('custom-tooltip__text')
      ? CustomTooltipComponent
      : AwesomeTooltipComponent;
    const tooltipPortal: ComponentPortal<any> = new ComponentPortal(component);
    const tooltipRef: ComponentRef<any> = this.overlayRef?.attach(tooltipPortal);
    tooltipRef.instance.text = this.elementRef.nativeElement.innerText;
    if (component === CustomTooltipComponent) {
      tooltipRef.instance.lgSize = this.lgSize;
      tooltipRef.instance.editDate = this.editDate;
      tooltipRef.instance.isNotification = this.isNotification;
      tooltipRef.instance.isViventium = this.isViventium;
    }
  }

  private onDocumentTouch(event: TouchEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.overlayRef?.detach();
      this.touched = false;
    }
  }
}
