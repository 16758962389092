<div
  [class.is-viventium]="isViventium"
  [class.is-notification]="isNotification"
  class="ct-wr">
  @if (isViventium) {
    <div>
      <ul>
        @for (item of text; track $index) {
          <li>{{ item }}</li>
        }
      </ul>
    </div>
  } @else {
    <div [class.lg]="lgSize" [class.edit]="editDate">{{ text }}</div>
  }
</div>
