import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTooltipComponent implements OnInit {
  @Input() isNotification: boolean = false;
  @Input() isViventium: boolean = false;
  @Input() lgSize: boolean = false;
  @Input() editDate: boolean = false;
  @Input() text = null;

  ngOnInit() {
    this.parseText();
  }

  private parseText() {
    if (this.isViventium) {
      this.text = this.text
        .split(',')
        .map((item: string): string => item.trim())
        .filter((item: string): boolean => item.length > 0)
        .slice(0, 10);
    }
  }
}
